<template>
  <div id="book" class="booking-section">
    <div class="indipendece-section" v-if="step === 0">
      <BookingSectionV0 @zeroStep="parseZeroStep"></BookingSectionV0>
    </div>

    <div class="indipendece-section" v-if="step === 1">
      <BookingSectionV1 @firstStep="parseFirstStep"></BookingSectionV1>
    </div>

    <div class="indipendece-section" v-if="step === 2">
      <BookingSectionV2
        :loading="loading"
        :discountedPrice="discountedPrice"
        :fullPrice="fullPrice"
        @goBack="goBack"
        @secondStep="parseSecondStep"
      >
      </BookingSectionV2>
    </div>
  </div>
</template>

<script>
import BookingSectionV0 from "./BookingSectionV0.vue";
import BookingSectionV1 from "./BookingSectionV1.vue";
import BookingSectionV2 from "./BookingSectionV2.vue";

export default {
  name: "BookingSection",
  components: {
    BookingSectionV0,
    BookingSectionV1,
    BookingSectionV2,
  },
  data() {
    return {
      createOrderPayload: {},
      step: 0,
      loading: false,
    };
  },
  methods: {
    checkout() {
      this.loading = true;
      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/create-order",
          this.createOrderPayload
        )
        .then((response) => {
          window.location.href = response.data.Location;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    parseZeroStep(payload) {
      this.createOrderPayload = {
        ...payload.payload,
      };
      this.step = 1;
    },
    parseFirstStep(payload) {
      this.createOrderPayload = {
        ...payload.payload,
      };
      this.fullPrice = payload.fullPrice;
      this.discountedPrice = payload.discountedPrice;
      if(payload.priceVoucher){ //Se esiste un voucher
        this.discountedPrice = payload.priceVoucher;
        delete payload.priceVoucher; // Rimuovi il campo priceVoucher dal payload
      }
      this.step = 2;
    },
    parseSecondStep(payload) {
      this.createOrderPayload = {
        ...this.createOrderPayload,
        ...payload,
      };
      this.loading = true;
      
      this.checkout();
    },

    goBack () {
            console.log("GOING BACK")
            this.step = 1
        }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.booking-section {
  width: 90%;
}

.indipendece-section {
  display: block;
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Effetto ombra per rilievo */
  border-radius: 10px;

  background-color: white;
  padding: 60px;

  z-index: 1;
}

@media (min-width: 1001px) and (max-width: 1250px) {
  .indipendece-section {
    top: -30px;
    left: 0;
    padding: 35px;
  }
}
@media (max-width: 1000px) {
  .booking-section {
    position: relative;
   
  }

  .indipendece-section {
    position: relative;

    left: 0;
    display: block;
    flex-direction: column;
    padding: 30px 40px 30px 40px;

    box-shadow: none;
    border-radius: 10px;

    background-color: white;
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .indipendece-section {
    top: -30%;
  }
}

@media (width: 540px) and (height: 600px) {
  .indipendece-section {
    top: 0;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .indipendece-section {
    position: relative;
    top: -80%;
    left: 0;
    padding: 18px;
  }
  
}

@media (min-width: 350px) and (max-width: 399px) {
  .indipendece-section {
    position: relative;
    top: -80%;
    left: 0;
    padding: 18px;
  }
  
}

@media (min-width: 200px) and (max-width: 349px) {
  .indipendece-section {
    position: relative;
    top: -18%;
    left: 0;
    padding: 25px;
  }
}
</style>
